<template>
  <v-container class="pa-0 ma-0">
    <div
      class="content_card"
      style="
        min-height: calc(100vh - 188px);
        display: block;
        padding-bottom: 0px;
      "
    >
      <div class="tab_container">
        <v-tabs v-model="tabs" fixed-tabs class="tab_navigation_drawer">
          <v-tab href="#mobile-tabs-5-1"> {{ $t("common.realtime") }} </v-tab>
          <v-tab href="#mobile-tabs-5-2"> {{ $t("common.statistics") }} </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tabs" style="padding-top: 55px" touchless>
        <v-tab-item :value="'mobile-tabs-5-' + 1" style="position: relative">
          <realtime />
        </v-tab-item>
        <v-tab-item :value="'mobile-tabs-5-' + 2"> <statistics /> </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import Realtime from "./Realtime.vue";
import Statistics from "./Statistics.vue";
export default {
  components: { Realtime, Statistics },
  data() {
    return {
      tabs: null,
      deviceId: "",
    };
  },
  async mounted() {},
  methods: {
    render(deviceId = "") {
      this.deviceId = deviceId;
    },
  },
};
</script>
