<template>
  <div class="ble_result_container">
    <v-data-table
      :headers="headers"
      :items="datapointsTable"
      class="elevation-1 -break"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td
            style="min-width: 100px"
            v-for="header in headers"
            :key="header.value"
            v-html="formatter(header.value, item[header.value])"
          ></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { headers, formatter } from "./constants";
export default {
  computed: {
    headers() {
      var result = headers.map((prop) => ({
        text: this.$t(`tbq.${prop}`),
        value: prop,
        sortable: false,
      }));
      result.unshift({
        text: this.$t(`common.datetime`),
        value: "time",
        sortable: false,
      });
      return result;
    },
  },
  data() {
    return {
      deviceId: "",
      datapointsTable: [],
    };
  },
  async mounted() {
    this.deviceId = this.$route.params.id;
    this.renderLogs();
  },
  methods: {
    formatter(prop, val) {
      return formatter[prop](val);
    },
    async registerWatcher() {
      this.$dbWatcherNoPrefix(
        `holtervn/${this.deviceId}/realtime`,
        async (logObj) => {
          if (!logObj) return;
          var time = moment(logObj.tickLabel).format("HH:mm:ss DD/MM/YYYY");
          this.datapointsTable.unshift({
            time,
            ...logObj,
            ma_may: this.deviceId,
          });
        }
      );
    },
    async renderLogs() {
      var todayString = moment().format("YYYY-MM-DD");
      var logs =
        (await this.$dbGetNoPrefix(
          `holtervn/${this.deviceId}/dataLogs/${todayString}`
        )) || {};
      await this.transformLogsIntoItems(logs);
      this.registerWatcher();
    },
    async transformLogsIntoItems(logObj = {}) {
      for (var timeStr in logObj) {
        for (var props in logObj[timeStr]) {
          if (!logObj[timeStr][props]) {
            logObj[timeStr][props] = "---";
          }
        }
        var dateStr = moment().format("YYYY-MM-DD");
        var time = moment(
          `${dateStr} ${timeStr}`,
          "YYYY-MM-DD HH-mm-ss"
        ).format("HH:mm:ss DD/MM/YYYY");
        this.datapointsTable.unshift({
          time,
          ...logObj[timeStr],
          ma_may: this.deviceId,
        });
      }
    },
  },
};
</script>
