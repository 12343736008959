<template>
  <div class="ble_result_container pt-2" style="overflow-x: scroll !important">
    <h3
      v-if="item && item.hien_thi_loi && item.hien_thi_loi != '0'"
      class="orange--text w-100 mt-2"
      style="text-align: center"
    >
      <v-icon color="orange" small>mdi-alert</v-icon>
      {{ item.hien_thi_loi.length > 1 ? item.hien_thi_loi.length : "Cảnh báo" }}
    </h3>
    <h3
      class="no_data text--center"
      :class="{ 'red--text': !isOnline, 'green--text': isOnline }"
    >
      <span style="font-size: 16px">● </span>
      {{ isOnline ? $t("tbq.online") : $t("tbq.offline") }}
    </h3>
    <h3 v-if="item.tickLabel" class="no_data text--center mt-2">
      {{ $t("tbq.lastOnline") }} {{ formatTime(item.tickLabel) }}
    </h3>
    <table>
      <tr v-for="(prop, index) in headers" :key="prop">
        <td class="pr-2 pl-1" style="min-width: 150px">
          <h3 class="name">{{ index + 1 }}. {{ $t(`tbq.${prop}`) }}:</h3>
        </td>
        <td>
          <h1 class="value right" v-html="formatter(prop, item[prop])"></h1>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// import moment from "moment";
import moment from "moment";
import { headers, formatter } from "./constants";

export default {
  data() {
    return {
      headers,
      item: {},
      deviceId: "",
      isOnline: false,
      lastOnlineTime: "",
      tickLabel: "",
      onlineWatcherIntervalSeconds: 5,
    };
  },
  mounted() {
    this.deviceId = this.$route.params.id;
    this.registerDataWatcher();
    this.registerOnlineWatcher();
  },
  methods: {
    formatter(prop, val) {
      return formatter[prop](val);
    },
    formatTime(val) {
      return moment(val).format("HH:mm:ss, DD/MM/YYYY");
    },
    async registerOnlineWatcher() {
      setInterval(async () => {
        await this.checkOnlineStatus();
      }, this.onlineWatcherIntervalSeconds * 1000);
    },
    async checkOnlineStatus() {
      if (!this.item.tickLabel) return (this.isOnline = false);
      if (
        moment(this.item.tickLabel)
          .add(this.onlineWatcherIntervalSeconds, "seconds")
          .isAfter(moment())
      ) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    async registerDataWatcher() {
      this.item.ma_may = this.deviceId;
      this.$dbWatcherNoPrefix(
        `holtervn/${this.deviceId}/realtime`,
        async (data) => {
          if (!data || !data.tickLabel) return;
          this.item = data;
          await this.checkOnlineStatus();
          this.$forceUpdate();
        }
      );
    },
  },
};
</script>
