import i18n from "@/lang/i18n";

const isVn = () => i18n.locale == "vn";

export const headers = [
  "statusText",
  "ma_may",
  "may_nen",
  "quat_lanh",
  "quat_nong",
  "cua_may",
  "nhiet_do_trong_tu",
  "nhiet_do_moi_truong",
  "do_am_moi_truong",
  "nhiet_do_cai_dat",
  "thoi_gian_mo_tu",
  "thoi_gian_cai_dat_bao_ve_block",
  "dong_hoat_dong_cua_block",
  "hien_thi_loi",
];

const zeroPad = (num, places) => String(num).padStart(places, "0");

export const formatter = {
  time: (val) => (val == undefined ? "---" : val),
  ma_may: (val) => (val == undefined ? "---" : val),
  statusText: (val) => (val == undefined ? "---" : val),
  may_nen: (val) => {
    if (val == undefined) return "---";
    var text = i18n.t("tbq.chay");
    var colorCode = "green--text";
    if (!val || val == "TAT" || val == "DONG") {
      colorCode = "orange--text";
      text = i18n.t("tbq.dung");
    }
    return `<span class="${colorCode}">${text}<span>`;
  },
  quat_lanh: (val) => {
    if (val == undefined) return "---";
    var text = i18n.t("tbq.chay");
    var colorCode = "green--text";
    if (!val || val == "TAT" || val == "DONG") {
      colorCode = "orange--text";
      text = i18n.t("tbq.dung");
    }
    return `<span class="${colorCode}">${text}<span>`;
  },
  quat_nong: (val) => {
    if (val == undefined) return "---";
    var text = i18n.t("tbq.chay");
    var colorCode = "green--text";
    if (!val || val == "TAT" || val == "DONG") {
      colorCode = "orange--text";
      text = i18n.t("tbq.dung");
    }
    return `<span class="${colorCode}">${text}<span>`;
  },
  cua_may: (val) => {
    if (val == undefined) return "---";
    var text = i18n.t("tbq.mo");
    var colorCode = "green--text";
    if (!val || val == "TAT" || val == "DONG") {
      colorCode = "orange--text";
      text = i18n.t("tbq.dong");
    }
    return `<span class="${colorCode}">${text}<span>`;
  },
  nhiet_do_trong_tu: (val) =>
    val == undefined ? "---" : `${zeroPad(val, 2)} <sup>o</sup>C`,
  nhiet_do_moi_truong: (val) =>
    val == undefined ? "---" : `${zeroPad(val, 2)} <sup>o</sup>C`,
  do_am_moi_truong: (val) => (val == undefined ? "---" : `${val} %`),
  nhiet_do_cai_dat: (val) =>
    val == undefined ? "---" : `${zeroPad(val, 2)} <sup>o</sup>C`,
  thoi_gian_mo_tu: (val) =>
    val == undefined ? "---" : `${zeroPad(val, 2)} ${isVn() ? "s" : "s"}`,
  thoi_gian_cai_dat_bao_ve_block: (val) =>
    val == undefined ? "---" : `${zeroPad(val, 2)} ${isVn() ? "m" : "m"}`,
  dong_hoat_dong_cua_block: (val) => (val == undefined ? "---" : `${val} amp`),
  hien_thi_loi: (val) => (val == undefined ? "---" : val),
};
